import http from "@/http/http.interceptors";
import {Message} from "element-ui";

/**
 *
 * @param {*} url 路径
 * @param {*} data 筛选条件
 * @param name 下载名称
 */
export function exportFile(url, data,name) {
  let fileName = name || 'download.xls'
  let formData = new FormData()
  formData.append("fileUrl",url)
  formData.append("fileName",name)
  http({
    method: 'POST',
    url: '/api/fileutils/downloadOssFile',
    responseType: 'blob',
    data: formData
  }).then((res) => {
    // if (res.headers["content-disposition"] !== undefined || res.headers["Content-Disposition"] !== undefined) {
    //   var temp = res.headers["content-disposition"].split(";")[1].split("=")[1];
    //   var fileName = window.decodeURIComponent(temp);
      var blob = new Blob([res.data]);
      //兼容IE浏览器、以及IE内核
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      }
  })
}

/**
 *
 * @param {*} url 路径
 * @param name 预览名称
 */
 export function getFileViewUrl(url, params, callback) {
  // http.post("/wopi/getToken",{ fileName: name, url }).then((res) => {
  const newAry = []
  const paramsKey = Object.keys(params)
  paramsKey&&paramsKey.forEach(item => {
    newAry.push(`${item}=${params[item]}`)
  })
  http.get(`/api/upload/view?${newAry.join('&')}&waterName`).then((res) => {
    console.log(res);
    if(res.data.code === 0){
      callback && callback(res.data.data)
    } else {
      callback && callback(false)
      this.$message.error(res.data.msg)
    }
  })
}
