<template>
  <el-dialog class="upload-file-dialog" title="视频" :visible.sync="visible" :uploadVideoDialogVisible="uploadVideoDialogVisible" :append-to-body="true" :destroy-on-close="true" @before-close="handleClose" @close="handleClose" width="50%">
    <el-form :model="uploadVideoForm" ref="uploadVideoForm" :rules="rules" label-width="6.25vw"><!--v-loading.fullscreen.lock="uploadingFlag"-->
      <el-row>
        <el-col :span="24">
          <el-form-item class="form-choose-item" label="名称：" prop="videoName">
            <el-input class="form-input" v-model="uploadVideoForm.videoName" placeholder="请输入视频名称" :maxlength="50"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="封面：" class="form-choose-item">
            <el-upload class="avatar-uploader" :action="uploadUrl" :headers="uploadHeader" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
              <img v-if="uploadVideoForm.imageUrl" :src="uploadVideoForm.imageUrl" class="avatar"/>
              <i v-if="uploadVideoForm.imageUrl" class="el-icon-error delete-icon" @click.stop="delVideoImg"></i>
              <div v-else class="avatar-uploader-info">
                <img class="avatar-uploader-icon" :src="uploadImgIcon" />
                <div class="avatar-uploader-title">上传图片</div>
                <div class="avatar-uploader-tip">支持格式：.png,.jpg,.jpeg，单个文件不能超过20MB，尺寸比例：4:3</div>
              </div>
            </el-upload>
            <!-- <div class="upload-tip">支持格式：.png,jpg,jpeg，单个文件不能超过20MB，尺寸比例：4:3</div> -->
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item class="form-choose-item" label="链接：" prop="videoUrl">
            <div class="upload-tip">支持格式：.mp4,.avi</div><!--,.mp3,.avi-->
            <el-input class="form-input" v-model="uploadVideoForm.videoUrl" placeholder="请输入链接地址" :maxlength="500"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="简介：">
            <el-input class="form-input" type="textarea" :rows="5" placeholder="请输入简介" v-model="uploadVideoForm.remark" resize="none" :maxlength="100" show-word-limit></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button :loading="buttonloading" @click="handleClose">取 消</el-button>
      <el-button :loading="buttonloading" type="primary" @click="handleSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { getToken } from "@/utils/auth";
import { noSymbol, videoUrlAuth } from "@/utils/validate.js";
import uploadImgIcon from '@/assets/img/upload-img-icon.png'
export default {
  name: "uploadFile",
  props: {
    uploadVideoDialogVisible: {
      type: Boolean,
      default: false,
    },
    uploadVideoHandleType: {
      type: String,
      default: "",
    },
    formData: {
      type: Object,
      default: {},
    },
  },
  watch: {
    uploadVideoDialogVisible() {
      this.visible = this.uploadVideoDialogVisible;
      if (this.uploadVideoDialogVisible) {
      }
    },
    formData: {
      handler(val) {
        if (JSON.stringify(val) != "{}") {
          this.uploadVideoForm = { ...this.formData };
        }
      },
      deep: true,
    },
  },
  data() {
    let checkName = (rule, value, callback) => {
      if (value === "") {
        return callback();
      }
      if (value !== "" && !noSymbol(value)) {
        callback(new Error('只支持汉字、数字、字母组合'));
      } else {
        callback();
      }
    };
    let checkVideoUrl = (rule, value, callback) => {
      if (value === "") {
        return callback();
      }
      if (value !== "" && !videoUrlAuth(value)) {
        callback(new Error('请输入以http或者https开头且以.mp4/.avi结尾的链接'));
      } else {
        callback();
      }
    };
    return {
      visible: false, //弹框显隐
      buttonloading: false, //按钮loading
      uploadingFlag: null, //全局loading
      uploadVideoForm: {
        videoName: "",
        imageUrl: "",
        videoUrl: "",
        remark: "",
      }, //上传form
      rules: {
        videoName: [
          { required: true, message: "请输入视频名称", trigger: "blur" },
          { min: 2, message: "视频名称长度不能小于2", trigger: "blur" },
          // { validator: checkName, trigger: 'blur' }
        ],
        videoUrl: [
          { required: true, message: "请输入视频链接", trigger: "blur" },
          { validator: checkVideoUrl, trigger: 'blur' }
        ],
      },
      uploadUrl: (process.env.NODE_ENV == "development" ? process.env.VUE_APP_LOCAL_URL + "/webForm" : process.env.VUE_APP_LOCAL_URL) + "/api/upload/uploadImgToOSS", //上传地址
      uploadHeader: { token: this.getToken() },
      fileList: [],
      fileTypeList: ["png", "jpg", "jpeg"], //文件类型list
      uploadImgIcon, //上传icon
    };
  },
  methods: {
    getToken,
    //上传封面成功事件
    handleAvatarSuccess(res, file) {
      this.uploadVideoForm.imageUrl = res;
      this.uploadingFlag.close()
    },
    //上传封面之前事件
    beforeAvatarUpload(file) {
      const isJPG = this.fileTypeList.indexOf(file.name.substr(file.name.lastIndexOf(".")+1,file.name.length).toLowerCase()) !== -1;
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error("上传图片只能是 png, jpg, jpeg 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }
      if(isJPG && isLt2M){
        this.uploadingFlag = this.$loading({
          lock: true,
          fullscreen: true,
          text: '上传中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      }
      return isJPG && isLt2M; //&& isLt2M
    },
    //删除图片
    delVideoImg() {//"确定删除该封面图片, 是否继续?"
      const h = this.$createElement;
      this.$confirm(
        h('p', null, [
          h('span', { style: 'color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;' }, '确定要删除该封面图片？'),
          h('div', { style: 'color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px' }, '是否确定删除该封面图片，删除后，将无法恢复。')
        ]), "提示", { confirmButtonText: "确定", cancelButtonText: "取消", iconClass: "el-icon-question", type: "warning", customClass: "warningMessage"
      }).then(() => {
        this.uploadVideoForm.imageUrl = "";
      }).catch(() => {});
    },
    //上传视频弹窗关闭
    handleClose() {
      this.fileList = [];
      this.uploadVideoForm = {
        videoName: "",
        imageUrl: "",
        videoUrl: "",
        remark: "",
      };
      this.buttonloading = false;
      this.$emit("update:uploadVideoDialogVisible", false);
    },
    //上传视频保存
    handleSubmit() {
      this.$refs.uploadVideoForm.validate((valid) => {
        if (valid) {
          this.buttonloading = true;
          this.$emit("getFileData", {
            formData: this.uploadVideoForm,
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.el-textarea{
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  &:hover{
    border-color: #c0c4cc;
  }
}
/deep/.el-textarea__inner{
  margin-bottom: 18px;
  border: none;
}
/deep/.el-input__count{
  bottom: 10px;
  line-height: normal;
  &::before{
    content: "已输入 ";
  }
}
</style>