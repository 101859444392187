<template>
  <el-dialog class="content-dialog" title="编辑" :visible.sync="visible" :contentDialogVisible="contentDialogVisible" :append-to-body="true" :destroy-on-close="true" @before-close="handleClose" @close="handleClose" width="70%">
    <el-form :model="contentForm" :rules="rules" ref="contentForm" label-width="6.25vw">
      <el-row>
        <el-col :span="24">
          <el-form-item label="名称：" class="form-choose-item" prop="name">
            <el-input class="form-input" v-model="contentForm.name" placeholder="请输入名称，支持汉字、数字、字母组合" :maxlength="50"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="contentDialogType == 'normal' ? '内容：' : '段落内容：'" prop="content">
            <!-- <quill-editor v-if="visible" style="width: 100%;" v-model="contentForm.content" :options="editorOption" ref="QuillEditor" class="textEditor"></quill-editor> -->
            <editor-component style="width: 100%" ref="courseContentComponent" :id="'courseContent'" :height="'300'" :defaultContent="editorContent" @getContent="getContent"></editor-component>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="24" style="width: 0">
          <el-upload style="opacity: 0" :action="uploadUrl" :headers="uploadHeader" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
            <el-button id="uploadButton" size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-col> -->
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button :loading="buttonloading" @click="handleClose">取 消</el-button>
      <el-button :loading="buttonloading" type="primary" @click="handleSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
// 工具栏配置
const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction
  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],
  ["link","image","video"],
  ["clean"], // remove formatting button
];
import { getToken } from "@/utils/auth";
import { noSymbol } from "@/utils/validate.js";
import EditorComponent from "@/components/editorComponent/index.vue";
export default {
  name: "contentComponent",
  components: {
    EditorComponent,
  },
  props: {
    contentDialogVisible: {
      type: Boolean,
      default: false,
    },
    contentDialogHandleType: {
      type: String,
      default: "",
    },
    contentDialogType: {
      type: String,
      default: "",
    },
    formData: {
      type: Object,
      default: {},
    },
  },
  watch: {
    contentDialogVisible() {
      this.visible = this.contentDialogVisible;
      if (this.contentDialogVisible) {
      }
    },
    formData: {
      handler(val) {
        if (JSON.stringify(val) != "{}") {
          this.contentForm = { ...this.formData };
          this.changeContent = this.contentForm.content
          this.editorContent = this.contentForm.content
        }
      },
      deep: true,
    },
  },
  data() {
    let checkContent = (rule, value, callback) => {
      if (this.changeContent === '<p><br/></p>') {
        callback(new Error('请输入内容'));
      } else {
        callback();
      }
    };
    return {
      visible: false, //弹框显隐
      buttonloading: false, //按钮loading
      contentForm: {
        name: "",
        // content: "",
      }, //图文输入form
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" },{ min: 2, message: "名称长度不能小于2", trigger: "blur" }], //,{ validator: checkName, trigger: 'blur' }
        content: [{ validator: checkContent, trigger: 'blur' }],
      },
      uploadUrl: (process.env.NODE_ENV == "development" ? process.env.VUE_APP_LOCAL_URL + "/webForm" : process.env.VUE_APP_LOCAL_URL) + "/api/upload/uploadImgToOSS", //上传地址
      uploadHeader: { token: this.getToken() },
      fileTypeList: ["png", "jpg", "jpeg", "gif"], //文件类型list
      editorOption: {
        //富文本配置
        modules: {
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              image: function (value) {
                if (value) {
                  document.getElementById("uploadButton").click();
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
        },
      },
      editorContent: "", //编辑器初始化内容
      changeContent: "", //改变的内容
    };
  },
  methods: {
    getToken,
    //富文本上传图片成功方法
    handleSuccess(res) {
      // 获取富文本组件实例
      let quill = this.$refs.QuillEditor.quill;
      // 如果上传成功
      if (res) {
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片，res为服务器返回的图片链接地址
        quill.insertEmbed(length, "image", res);
        // 调整光标到最后
        quill.setSelection(length + 1);
        this.$nextTick(() => {
          console.log(this.contentForm.content);
        });
      } else {
        // 提示信息，需引入Message
        Message.error("图片插入失败");
      }
    },
    //上传封面成功事件
    handleAvatarSuccess(res, file) {
      console.log(res);
      this.handleSuccess(res);
    },
    //上传封面之前事件
    beforeAvatarUpload(file) {
      const isJPG = this.fileTypeList.indexOf(file.name.substr(file.name.lastIndexOf(".")+1,file.name.length).toLowerCase()) !== -1;
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error("上传图片只能是 png, jpg, jpeg, gif 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }
      return isJPG && isLt2M; //&& isLt2M
    },
    //获取图文值
    getContent(data){
      this.changeContent = data.content
    },
    //图文编辑弹窗关闭
    handleClose() {
      this.contentForm = {
        name: "",
        // content: "",
      };
      this.editorContent = ""
      this.changeContent = ""
      this.buttonloading = false;
      this.$emit("update:contentDialogVisible", false);
    },
    //图文编辑保存
    handleSubmit() {
      this.$refs.contentForm.validate((valid) => {
        if (valid) {
          this.buttonloading = true;
          this.$emit("getFileData", { formData: { ...this.contentForm, content: this.changeContent}, });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.el-dialog__body{
  color: unset;
}
</style>