<template>
  <el-dialog class="upload-file-dialog"
             title="上传附件"
             :visible.sync="visible"
             :uploadFileDialogVisible="uploadFileDialogVisible"
             :append-to-body="true"
             :destroy-on-close="true"
             @before-close="handleClose"
             @close="handleClose"
             width="50%">
    <el-form :model="uploadFileForm"
             ref="uploadFileForm"
             label-width="6.25vw">
      <!--v-loading.fullscreen.lock="uploadingFlag"-->
      <el-row>
        <el-col :span="24">
          <el-form-item label="上传附件："
                        class="form-choose-item">
            <el-upload class="upload-demo"
                       :action="uploadUrl"
                       :headers="uploadHeader"
                       :data="uploadData"
                       :on-preview="handlePreview"
                       :on-remove="handleRemove"
                       :limit="1"
                       :on-exceed="handleExceed"
                       :file-list="fileList"
                       :on-success="handleAvatarSuccess"
                       :before-upload="beforeAvatarUpload"
                       :accept="'.pdf,.ppt,.pptx,.doc,.docx,.xlsx,.xls,.png,.jpg,.jpeg'">
              <el-button size="small"
                         type="primary">上传文件</el-button>
              <div slot="tip"
                   class="el-upload__tip"> 支持文件格式：.pdf .ppt .pptx .doc .docx .xlsx ，单个文件不能超过500MB；<br />支持图片格式：.png .jpg .jpeg，单个文件不能超过20MB；</div>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item class="form-choose-item"
                        label="下载：">
            <el-radio-group v-model="uploadFileForm.isDownload">
              <el-radio :label="'1'">支持</el-radio>
              <el-radio :label="'0'">不支持</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注：">
            <el-input class="form-input"
                      type="textarea"
                      :rows="5"
                      placeholder="请输入备注"
                      v-model="uploadFileForm.remark"
                      resize="none"
                      :maxlength="100"
                      show-word-limit></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer"
         class="dialog-footer">
      <el-button :loading="buttonloading"
                 @click="handleClose">取 消</el-button>
      <el-button :loading="buttonloading"
                 type="primary"
                 @click="handleSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { getToken } from "@/utils/auth";
export default {
  name: "uploadFile",
  props: {
    uploadFileDialogVisible: {
      type: Boolean,
      default: false
    },
    uploadFileHandleType: {
      type: String,
      default: ""
    },
    formData: {
      type: Object,
      default: {}
    }
  },
  watch: {
    uploadFileDialogVisible () {
      this.visible = this.uploadFileDialogVisible
      if (this.uploadFileDialogVisible) {

      }
    }
  },
  data () {
    return {
      visible: false, //弹框显隐
      buttonloading: false, //按钮loading
      uploadingFlag: null, //全局loading
      uploadFileForm: {
        isDownload: "1",
        remark: ""
      }, //上传form
      // rules: {
      //   imageUrl: [
      //     { required: true, message: "请上传视频封面", trigger: "change" },
      //   ],
      //   imageUrl: [
      //     { required: true, message: "请输入视频链接", trigger: "blur" },
      //   ],
      // },
      uploadUrl: (process.env.NODE_ENV == "development" ? process.env.VUE_APP_LOCAL_URL + "/webForm" : process.env.VUE_APP_LOCAL_URL) + "/api/upload/uploadImgAndFileToOSS", //上传地址
      uploadHeader: { token: this.getToken() },
      fileList: [],
      fileTypeList: ["pdf", "ppt", "pptx", "doc", "docx", "xlsx", "xls", "png", "jpg", "jpeg"], //文件类型list ,"png", "jpg", "jpeg"
      uploadData: { type: '2' }
    }
  },
  methods: {
    getToken,
    //删除上传文件事件
    handleRemove (file, fileList) {
      console.log(file, fileList);
      this.fileList = [];
    },
    //查看
    handlePreview (file) {
      console.log(file);
    },
    //超过限制提示
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    //上传成功事件
    handleAvatarSuccess (res, file) {
      console.log('附件上传成功res:', res);
      const { data: { fileUrl, fileId = "", fileVersionId = "" } } = res
      this.fileList = [{ url: fileUrl, fileId, fileVersionId, name: file.name }];
      this.uploadingFlag.close()
    },
    //上传之前事件
    beforeAvatarUpload (file) {
      let fileName = file.name.toLowerCase();
      const isJPG = this.fileTypeList.indexOf(fileName.substr(fileName.lastIndexOf(".") + 1, fileName.length)) != -1;
      const isLt2M = file.size / 1024 / 1024 < 20;
      const isLt5M = file.size / 1024 / 1024 < 500;
      const isNameLength = fileName.length <= 200;

      if (!isJPG) {
        this.$message.error("上传附件只能是 pdf, ppt, pptx, doc, docx, xlsx, xls 格式!"); //, png, jpg, jpeg
      }
      if (!isLt2M && ['png', 'jpg', 'jpeg'].indexOf(file.name.substr(file.name.lastIndexOf(".") + 1, file.name.length).toLowerCase()) != -1) {
        this.$message.error("上传图片大小不能超过 20MB!");
      }
      if (!isLt5M && ["pdf", "ppt", "pptx", "doc", "docx", "xlsx", "xls"].indexOf(file.name.substr(file.name.lastIndexOf(".") + 1, file.name.length).toLowerCase()) != -1) {
        this.$message.error("上传文件大小不能超过 500MB!");
      }
      if (!isNameLength) {
        this.$message.error("上传附件名称长度不能超过 200字!");
      }
      if (isJPG && ((isLt2M && ['png', 'jpg', 'jpeg'].indexOf(file.name.substr(file.name.lastIndexOf(".") + 1, file.name.length).toLowerCase()) != -1) || (isLt5M && ["pdf", "ppt", "pptx", "doc", "docx", "xlsx", "xls"].indexOf(file.name.substr(file.name.lastIndexOf(".") + 1, file.name.length).toLowerCase()) != -1)) && isNameLength) {
        this.uploadingFlag = this.$loading({
          lock: true,
          fullscreen: true,
          text: '上传中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        if (['png', 'jpg', 'jpeg'].indexOf(file.name.substr(file.name.lastIndexOf(".") + 1, file.name.length).toLowerCase()) != -1) {
          this.uploadData.type = '1'
        } else {
          this.uploadData.type = '2'
        }
      }
      return isJPG && ((isLt2M && ['png', 'jpg', 'jpeg'].indexOf(file.name.substr(file.name.lastIndexOf(".") + 1, file.name.length).toLowerCase()) != -1) || (isLt5M && ["pdf", "ppt", "pptx", "doc", "docx", "xlsx", "xls"].indexOf(file.name.substr(file.name.lastIndexOf(".") + 1, file.name.length).toLowerCase()) != -1)) && isNameLength;
    },
    //上传附件弹框关闭
    handleClose () {
      this.fileList = []
      this.uploadFileForm = {
        isDownload: "1",
        remark: ""
      }
      this.buttonloading = false;
      this.$emit("update:uploadFileDialogVisible", false);
    },
    //上传附件保存
    handleSubmit () {
      this.$refs.uploadFileForm.validate((valid) => {
        if (valid) {
          if (this.fileList.length == 0) {
            this.$message.error("请上传附件");
            return;
          }
          this.buttonloading = true;
          this.$emit("getFileData", { fileList: this.fileList, formData: this.uploadFileForm });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.el-textarea {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  &:hover {
    border-color: #c0c4cc;
  }
}
/deep/.el-textarea__inner {
  margin-bottom: 18px;
  border: none;
}
/deep/.el-input__count {
  bottom: 10px;
  line-height: normal;
  &::before {
    content: '已输入 ';
  }
}
</style>
